import base64url from 'base64-url'

/**
 * Converts a URL-safe base64 encoded string into a Uint8Array.
 *
 * @param {string} base64String - The URL-safe base64 encoded string to convert.
 * @returns {Uint8Array} - A Uint8Array containing the binary data represented by the input string.
 */
const urlBase64ToUint8Array = (base64String: string) => {
  const base64 = base64url.unescape(base64String)

  const rawData = atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }

  return outputArray
}

export default urlBase64ToUint8Array
