import React from 'react'
import { Popover as HeadlessUiPopover, Transition } from '@headlessui/react'
import { Options } from '@popperjs/core'
import { usePopper } from 'react-popper'
import classNames from 'classnames'
import merge from 'lodash/merge'

export type GenericPopOverProps = {
  button?: React.ReactNode
  popperOptions?: Partial<Options>
  wrapperClassName?: string
  buttonClassName?: string
  panelClassName?: string
  variant?: Variant
  buttonAs?: React.ElementType
  openOnLoad?: boolean
}

export enum Variant {
  DEFAULT = 'default',
  ERROR = 'error',
  INFO = 'info'
}

export default function PopOver({
  button,
  popperOptions,
  wrapperClassName,
  buttonClassName,
  panelClassName,
  variant = Variant.DEFAULT,
  buttonAs = 'button',
  children,
  openOnLoad
}: React.PropsWithChildren & GenericPopOverProps) {
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null)
  const [arrowElement, setArrowElement] = React.useState<HTMLDivElement | null>(
    null
  )

  React.useEffect(() => {
    if (referenceElement && openOnLoad) {
      referenceElement.click()
    }
  }, [referenceElement, openOnLoad])

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    merge(
      {
        placement: 'right',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              padding: 15
            }
          },
          { name: 'offset', options: { offset: [0, 5] } },
          { name: 'arrow', options: { element: arrowElement } }
        ]
      },
      popperOptions
    )
  )

  return (
    <HeadlessUiPopover
      className={classNames(wrapperClassName, 'flex items-center')}
    >
      <HeadlessUiPopover.Button
        as={buttonAs}
        ref={setReferenceElement}
        className={buttonClassName}
      >
        {button}
      </HeadlessUiPopover.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className="z-50"
      >
        <HeadlessUiPopover.Panel
          className={classNames(
            'py-3 px-4 rounded-xl shadow-2xl text-sm w-[200px] flex flex-col',
            { 'bg-white text-black': variant === Variant.DEFAULT },
            { 'bg-fadedRed text-black': variant === Variant.ERROR },
            { 'bg-white text-black': variant === Variant.INFO },
            panelClassName
          )}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          static
        >
          <div ref={setArrowElement} style={styles.arrow} />
          {children}
        </HeadlessUiPopover.Panel>
      </Transition>
    </HeadlessUiPopover>
  )
}
