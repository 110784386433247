import React, { createContext } from 'react'

interface ContextVariables {
  navigationIsOpen: boolean
  setNavigationIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const NavigationContext = createContext<ContextVariables>({
  setNavigationIsOpen: () => undefined,
  navigationIsOpen: false
})

export const useNavigationContext = () => React.useContext(NavigationContext)

export const NavigationContextProvider = ({
  children
}: React.PropsWithChildren) => {
  const [navigationIsOpen, setNavigationIsOpen] = React.useState(false)

  return (
    <NavigationContext.Provider
      value={{ navigationIsOpen, setNavigationIsOpen }}
    >
      {children}
    </NavigationContext.Provider>
  )
}
