import classNames from 'classnames'
import { motion, useScroll, useTransform } from 'framer-motion'
import React, { useEffect } from 'react'

interface ScrollContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  show?: boolean
  className?: string
  wrapperClassName?: string
  // The maximum opacity of the shadow. Should be between 0 and 1.
  maxOpacity?: number
  fullHeight?: boolean
  direction?: 'vertical' | 'horizontal'
}

/**
 * Component that wraps its children in a scrollable container
 * that displays a shadow at the top and bottom when scrolling
 */
const ScrollContainerForwardRef = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ScrollContainerProps>
>(function ScrollContainer(
  {
    show = true,
    maxOpacity = 1,
    children,
    className,
    wrapperClassName,
    fullHeight,
    direction = 'vertical',
    ...restProps
  },
  forwardedRef
) {
  const contentsRef = React.useRef<HTMLDivElement>(null)
  const scrollRef = React.useRef<HTMLDivElement>(null)
  const [showShadow, setShowShadow] = React.useState(show)
  const { scrollYProgress, scrollXProgress } = useScroll({
    container: scrollRef
  })

  const isHorizontal = direction === 'horizontal'

  const scrollProgressClamped = useTransform(
    isHorizontal ? scrollXProgress : scrollYProgress,
    [0, 1],
    [0, maxOpacity]
  )
  const inverseScrollProgress = useTransform(
    scrollProgressClamped,
    [0, maxOpacity],
    [maxOpacity, 0]
  )

  const setShadowVisibility = React.useCallback(() => {
    const contents = contentsRef.current
    const scroll = scrollRef.current

    if (!contents || !scroll) return

    // only show scroll if contents are overflowing
    if (isHorizontal) {
      setShowShadow(contents.scrollWidth > scroll.clientWidth)
    } else {
      setShowShadow(contents.scrollHeight > scroll.clientHeight)
    }
  }, [isHorizontal])

  useEffect(() => {
    const scroll = scrollRef.current
    const observer = new ResizeObserver(() => setShadowVisibility())

    if (scroll) {
      observer.observe(scroll)

      return () => observer.unobserve(scroll)
    }
  }, [setShadowVisibility])

  React.useEffect(() => {
    setShadowVisibility()
  }, [setShadowVisibility])

  return (
    <div
      ref={forwardedRef}
      className={classNames(
        wrapperClassName,
        { 'h-full': fullHeight },
        'relative overflow-hidden '
      )}
      {...restProps}
    >
      <div
        onScroll={setShadowVisibility}
        ref={scrollRef}
        className={classNames(
          'w-full relative overflow-auto scrollbar-hide h-full',
          { 'overflow-y-hidden': isHorizontal }
        )}
      >
        <div
          ref={contentsRef}
          className={classNames({ flex: isHorizontal }, className)}
        >
          {children}
        </div>
      </div>
      {showShadow && (
        <>
          <motion.div
            className={classNames(
              'absolute pointer-events-none from-white dark:from-deepBlue',
              isHorizontal
                ? ' left-0 top-0 bg-gradient-to-r h-full w-[60px]'
                : 'top-0 left-0 bg-gradient-to-b h-12 w-full'
            )}
            style={{ opacity: scrollProgressClamped }}
          />
          <motion.div
            className={classNames(
              'absolute pointer-events-none from-white dark:from-deepBlue',
              isHorizontal
                ? 'right-0 top-0 bg-gradient-to-l h-full w-[60px]'
                : 'bottom-0 left-0 bg-gradient-to-t h-12 w-full'
            )}
            style={{ opacity: inverseScrollProgress }}
          />
        </>
      )}
    </div>
  )
})

export default ScrollContainerForwardRef
