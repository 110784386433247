import { Field } from 'formik'

export const TextArea = ({
  name,
  label,
  error,
  height = 200
}: {
  name: string
  label: string
  error: string | undefined
  validator?: (value: string) => string | undefined
  height?: number
}) => {
  return (
    <div className="flex flex-col">
      <label htmlFor={name} className="w-full">
        {label}
      </label>
      <Field
        as="textarea"
        id={name}
        name={name}
        className="w-full rounded-lg mt-3 border-borderGray dark:border-darkBlueGray dark:bg-darkBlueGray text-sm"
        style={{
          height: `${height}px`
        }}
      />
      {error && <div className="text-red mt-2">{error}</div>}
    </div>
  )
}
