import { useUser } from '@auth0/nextjs-auth0/client'
import React, { createContext, useContext, useEffect } from 'react'
import NotificationServiceWorkerManager from './NotificationServiceWorkerManager'
import useUpdateSearchParams from '../../hooks/useUpdateSearchParams/useUpdateSearchParams'
import { useRouter } from 'next/navigation'

interface NotificationRFQAxe {
  axeId: string
  rfqId: string
  timestamp: number
}

interface NotificationsContextProps {
  requestNotificationPermission: () => void
  permissionGranted: boolean
  notificationRFQAxe: NotificationRFQAxe | null
  setNotificationRFQAxe?: React.Dispatch<
    React.SetStateAction<NotificationRFQAxe | null>
  >
}

const NotificationWorkerContext = createContext<NotificationsContextProps>({
  requestNotificationPermission: () => undefined,
  permissionGranted: false,
  notificationRFQAxe: null
})

export const useNotificationsContext = () =>
  useContext(NotificationWorkerContext)

interface NotificationWorkerContextProviderProps {
  children: React.ReactNode
}

const NotificationsProvider = ({
  children
}: NotificationWorkerContextProviderProps) => {
  const [permissionGranted, setPermissionGranted] =
    React.useState<boolean>(false)
  const [notificationRFQAxe, setNotificationRFQAxe] = React.useState<{
    axeId: string
    rfqId: string
    timestamp: number
  } | null>(null)
  const { user, isLoading } = useUser()
  const [notificationManager, setNotificationManager] =
    React.useState<NotificationServiceWorkerManager | null>(null)
  const managerRef = React.useRef<NotificationServiceWorkerManager | null>(null)

  const searchParamsUpdater = useUpdateSearchParams()
  const router = useRouter()

  useEffect(() => {
    const eventHandler = (event: MessageEvent) => {
      if (event.data.action !== 'redirect-from-notificationclick') return

      router.push(`/dashboard/trade`)

      const { rfqId, axeId } = event.data
      setNotificationRFQAxe({ axeId, rfqId, timestamp: Date.now() })
    }
    if (!navigator.serviceWorker) return

    navigator.serviceWorker.addEventListener('message', eventHandler)

    return () => {
      navigator.serviceWorker.removeEventListener('message', eventHandler)
    }
  }, [searchParamsUpdater, router])

  React.useEffect(() => {
    if (!user?.sub || isLoading) return

    if (!managerRef.current && navigator.serviceWorker) {
      const manager = new NotificationServiceWorkerManager(
        navigator.serviceWorker.register(
          new URL(
            '../../workers/notificationsWorker/notificationsWorker?v=2', // this version number needs to be updated when the worker is updated because the browser caches the worker
            import.meta.url
          )
        ),
        user.sub
      )
      managerRef.current = manager
      manager.registerServiceWorker().then(() => {
        setNotificationManager(manager)
      })
    }
  }, [user?.sub, isLoading])

  const requestNotificationPermission = async () => {
    if (!notificationManager) return

    const permission = await notificationManager.requestPermissions()
    setPermissionGranted(permission)
  }

  React.useEffect(() => {
    if (!notificationManager) return

    notificationManager.hasPermissions().then((permission) => {
      setPermissionGranted(permission)
    })
  }, [notificationManager])

  return (
    <NotificationWorkerContext.Provider
      value={{
        requestNotificationPermission,
        permissionGranted,
        notificationRFQAxe,
        setNotificationRFQAxe
      }}
    >
      {children}
    </NotificationWorkerContext.Provider>
  )
}

export default NotificationsProvider
