import urlBase64ToUint8Array from '../../utils/urlBase64ToUint8Array/urlBase64ToUint8Array'

class NotificationServiceWorkerManager {
  // Pass the service worker registration function; it must be in the same scope as the service worker file.
  private serviceWorkerRegistration: Promise<ServiceWorkerRegistration>
  private registration: ServiceWorkerRegistration | undefined
  private userId = ''

  constructor(
    serviceWorkerRegistration: Promise<ServiceWorkerRegistration>,
    userId: string
  ) {
    this.serviceWorkerRegistration = serviceWorkerRegistration
    this.userId = userId
  }

  // Handle these error with an error boundary
  // daveymoores/opt-661-build-error-fallback-if-check-compatibility-fails
  public isCompatible(): boolean {
    if (!('serviceWorker' in navigator)) {
      throw new Error('No support for service worker!')
    }

    if (!('Notification' in window)) {
      throw new Error('No support for notification API')
    }

    if (!('PushManager' in window)) {
      throw new Error('No support for Push API')
    }

    return true
  }

  private hasPermission = (state: PermissionState | NotificationPermission) =>
    state === 'granted'

  // Check permissions as they can be revoked by the user. Revoking from the app is not supported due to changes in Firefox 51. See MDN link for details.
  // https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API/Using_the_Permissions_API#revoking_permissions
  public async hasPermissions() {
    const { state } = await navigator.permissions.query({
      name: 'notifications'
    })

    return state === 'granted'
  }

  public async requestPermissions() {
    if (!this.isCompatible()) return false

    const permission = await Notification.requestPermission()

    return this.hasPermission(permission)
  }

  private async subscribeToPushNotifications(): Promise<PushSubscription | null> {
    const res = await fetch('/api/variables')
    const envVariables = await res.json()
    const applicationServerKey = urlBase64ToUint8Array(envVariables.vapidPubKey)

    return (
      this.registration?.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
      }) || null
    )
  }

  private saveSubscription(subscription: PushSubscription | null) {
    this.registration?.active?.postMessage(
      JSON.stringify({ userId: this.userId, subscription })
    )
  }

  private async registerSubscription() {
    if (!this.registration) throw new Error('No service worker registered')

    try {
      let subscription = await this.registration.pushManager.getSubscription()

      if (!subscription) {
        subscription = await this.subscribeToPushNotifications()
      }

      this.saveSubscription(subscription)
    } catch (error) {
      console.error('Error', error)
    }
  }

  public async registerServiceWorker(): Promise<void> {
    if (this.isCompatible()) {
      const registrations = await navigator.serviceWorker.getRegistrations()
      const notificationServiceWorkerExists = registrations.some(
        (registration) =>
          registration.active?.scriptURL.includes('notificationsWorker')
      )

      if (notificationServiceWorkerExists) {
        console.info('Service worker already registered.')
      } else {
        try {
          this.registration = await this.serviceWorkerRegistration
          await this.registerSubscription()
          console.info('Service worker registered')
        } catch (error) {
          console.error('Failed to register service worker:', error)
        }
      }
    }
  }
}

export default NotificationServiceWorkerManager
