import classNames from 'classnames'
import React from 'react'

export enum CheckBoxVariant {
  DEFAULT,
  DARK_BACKGROUND
}

type CheckBoxProps = React.HTMLProps<HTMLInputElement> & {
  variant?: CheckBoxVariant
}

export default function CheckBox({
  variant = CheckBoxVariant.DEFAULT,
  ...props
}: CheckBoxProps) {
  return (
    <input
      {...props}
      aria-label={props.name}
      type="checkbox"
      className={classNames(
        {
          'dark:bg-darkBlue rounded-sm': CheckBoxVariant.DEFAULT === variant,
          'dark:bg-gray-700 rounded':
            CheckBoxVariant.DARK_BACKGROUND === variant
        },
        'w-4 h-4 text-lightBlue bg-lightGray border-baseGray dark:border-lightBlue checked:bg-lightBlue focus:ring-blue-500 dark:focus:ring-blue-500 dark:ring-offset-gray-800 focus:ring-2'
      )}
    />
  )
}

// filled
//  text-blue-600 bg-gray-100
//  border-gray-300
//  --> rounded
//---->//  dark:bg-gray-700
//   dark:border-gray-600
//  --> mr-3
//  focus:ring-blue-500
//  dark:focus:ring-blue-600 dark:ring-offset-gray-800
// focus:ring-2

// //
// text-lightBlue bg-lightGray
// border-baseGray
//  --> dark:bg-darkBlue
//  dark:border-lightBlue
//  --> rounded-sm
//  --> checked:bg-lightBlue
//  focus:ring-blue-500
// dark:focus:ring-blue-500 dark:ring-offset-gray-800
// focus:ring-2
