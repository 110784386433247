import { usePathname, useRouter } from 'next/navigation'
import { useCallback } from 'react'

type SearchParamsValues = {
  axeId?: string
  userId?: string
  rfqId?: string
  searchAxeId?: string
}

const useUpdateSearchParams = () => {
  const router = useRouter()
  const pathname = usePathname()

  const callback = useCallback(
    (values?: SearchParamsValues) => {
      const searchParams = window.location.search
      const params = new URLSearchParams(searchParams || '')

      for (const name in values) {
        const value = values[name as keyof SearchParamsValues]
        value ? params.set(name, value) : params.delete(name)
      }
      router.push(pathname + '?' + params.toString())
    },
    [pathname, router]
  )

  return callback
}

export default useUpdateSearchParams
